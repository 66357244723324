<template>
  <div class="statistics">
    <p class="title">
      <svg class="icon shuaxinImg" aria-hidden="true" @click="refresh">
        <use href="#icon-sx-copy"></use>
      </svg>
      <span v-if="isQueryCount">
        <!-- 共{{total}}条数据 -->
        {{ $t("vue_label_commonobjects_view_sum", { total: total }) }},
      </span>
      <span v-show="showCheckedLength">
        <span v-if="field !== ''">
          <!-- 按{{field.trim()}}排序 -->
          {{
            $t("vue_label_commonobjects_view_sortby", { field: field.trim() })
          }},
        </span>
        <!-- 已选中{{checkedLength}}条数据 -->
        <span v-if="!isChecked">{{
          $t("vue_label_commonobjects_view_selected", {
            checkedLength: checkedLength,
          })
        }}</span>
      </span>
      <!-- 已在{{timeInterval}}刷新 -->
      {{
        $t("vue_label_commonobject_view_updatedago", {
          timeInterval: timeInterval,
        })
      }}
    </p>
  </div>
</template>

<script>
/**
 * @description: 内联编辑 编辑框
 * 功能:
 * 1.展示刷新按钮、总数据条数、表格字段排序信息、已选中数据条数、距离上次刷新的时间
 * 2.刷新
 */
export default {
  props: {
    /**
     * 是否展示数据总条数
     */
    isQueryCount: {
      type: Boolean,
      default: false,
    },
    /**
     * 数据总条数
     */
    total: {
      type: Number,
      default: 0,
    },
    /**
     * 是否展示排序字段和选中数据条数
     */
    showCheckedLength: {
      type: Boolean,
      default: true,
    },
    /**
     * 是否展示选中数据总条数
     */
    isChecked: {
      type: Boolean,
      default: false,
    },
    /**
     * 选中数据条数
     */
    checkedLength: {
      type: Number,
      default: 0,
    },
    /**
     * 排序字段名称
     */
    field: {
      type: String,
      default: "",
    },
    /**
     * 距离上次刷新的时间
     */
    timeInterval: {
      type: String,
      default: "",
    },
  },
  methods: {
    /**
     * 刷新
     */
    refresh() {
      this.$emit("refresh");
    },
  },
};
</script>

<style lang="scss" scoped>
.statistics {
  .shuaxinImg {
    cursor: pointer;
    margin-right: 5px;
    width: 12px;
    vertical-align: -1px;
    fill: #333333;
  }
  .shuaxinImg:active {
    fill: #006dcc;
  }
  .title {
    font-size: 12px;
    color: #333333;
  }

  img {
    position: relative;
    top: -1px;
  }
}
</style>
